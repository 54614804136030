/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
body,html{
	line-height: 1;
	font-family: 'PT Sans', sans-serif;
	color: #101010;
	font-size: 16px;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
p{
	margin-bottom: 1.3em;
}
a{
	color: var(--tooltip-color);
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
.disabled{
	display: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a, div{
	box-sizing: border-box;
}
h1, .h1{
	font-size: 2.5rem;
	font-weight: 600;
	text-transform: uppercase;
}
h2, .h2{
	font-size: 2rem;
	font-weight: 600;
	text-transform: initial;
}
h3, .h3{
	font-size: 1.75rem;
	font-weight: 600;
}
h4, .h4{
	font-size: 1.5rem;
}
h5, .h5{
	font-size: 1.25rem;
	font-weight: 600;
	text-transform: uppercase;
}
h6, .h6{
	font-size: 1rem;
	text-transform: uppercase;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6{
	margin-bottom: 1rem;
}
p,a,li{
	font-size: 1rem;
	font-weight: normal;
	text-transform: none;
}
b, strong{
	font-weight: 600;
}
main{
	max-width: 1360px;
	padding: 20px;
	width: 100%;
	margin:auto;
}
.page-template-page-composants{
	main{
		max-width: 100%;
		padding: 0;
	}
}
.container{
	max-width: 1360px;
	padding: 0 15px;
	width: 100%;
	margin:0 auto;
}

#custom-dropdown-search {
	display: flex;
	border: 2px solid #fff;
    border-radius: 10px;
    background: transparent;
    height: 46px;
	margin-left: 40px;
    min-width: 235px;
    color: #fff;
    font-family: "PT Sans",sans-serif;
    cursor: pointer;
    align-items: center;
    position: relative;
	.drowpdown-content {
		width: 100%;
		li {
			position: relative;
			margin-bottom: 25px;
			&:hover {
				font-weight: bold;
				&:before{
					max-width: 50px;
					right: 0;
					margin: auto;
					background: #fff;
					width: 100%;
					height: 2px;
					position: absolute;
					bottom: -10px;
					left: 0;
					content: "";
				}
			}
		}
		.dropdown-select {
			padding-left: 20px;
			background-image: url(../img/drop-down.png);
			background-repeat: no-repeat;
			background-position: calc(100% - 24px) center;
			.dropdown-select-select {
				background-color: transparent;
				width: 130px;
				border: none;
				cursor: pointer;
			}
			.dropdown-select-select::placeholder {
				color: #fff;
			}
		}
		.drowpdown-content-hide {
			display: none;
			position: absolute;
			z-index: 99;
			padding-top: 15px;
			margin-top: 15px;
			width: 100%;
			text-align: center;
			&:before {
					border-radius: 10px;
					background-color:  var(--tooltip-color);
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					opacity: 0.7;
					z-index: -1;
				}
		}
	}
}
.search-form-container {
	#custom-dropdown-search {
		border: 1px solid #000;
	    color: #000;
		.drowpdown-content {
			li {
				&:hover {
					&:before{
						background: #fff;
					}
				}
			}
			.dropdown-select {
				background-image: url(../img/drop-down-black.png);
				.dropdown-select-select::placeholder {
					color: #000;
				}
			}
			.drowpdown-content-hide {
				border: 1px solid #fff;
				border-radius: 10px;
				.dropdown-option {
					color: #fff;
				}
			}
		}
	}
	.search-form-container-results{
		font-size: 1.25rem;
	    color: #000;
	    text-decoration: none;
	    font-weight: bold;
	    color: var(--tooltip-color);
	}
}

form.search-form {
	max-width: 1340px;
	width: 100%;
	margin:auto;
	display: flex;
	align-items:center;
	font-family: 'PT Sans', sans-serif;
	> label{
		color: #fff;
		font-weight: 600;
	}
	> .search-form--text{
		margin-left: 40px;
		height: 46px;
		border: 2px solid #fff;
		border-radius: 10px;
		flex-grow:1;
		background: transparent;
		padding: 0 20px;
		color: #fff;
		font-family: 'PT Sans', sans-serif;
		-webkit-appearance: none;
		&::placeholder{
			color:#fff;
			font-style: italic;
		}
	}
	> .search-form--espace{
		border: 2px solid #fff;
		border-radius: 10px;
		background:transparent;
		height: 46px;
		margin-left: 40px;
		min-width: 235px;
		padding: 0 20px;
		color: #fff;
		font-family: 'PT Sans', sans-serif;
		cursor:pointer;
		background-image: url(../img/drop-down.png);
    	background-repeat: no-repeat;
    	background-position: calc(100% - 24px) center;
    	-webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	    -ms-appearance: none;
		option{
			color: #000;
			background: #fff;
		}
	}
	> .search-form--submit{
		height: 46px;
		border: 2px solid #fff;
		border-radius: 10px;
		background:transparent;
		color: #fff;
		text-transform: uppercase;
		padding: 5px 25px;
		margin-left: 40px;
		font-family: 'PT Sans', sans-serif;
		transition:0.3s;
		&:hover{
			background: #fff;
			color: var(--tooltip-color);
		}
	}
}
.composant-01 {
	.container-home{
		.composant-bottom-01{
			padding: 23px 0;
		}
	}
	.composant-01-search{
		background: var(--tooltip-color);
		height: 0;
		transition:0.3s;
		&.none{
			overflow: hidden;
		}
		.composant-01-search-inner{
			padding: 27px 15px;
		}
	}
	.composant-01-inner {
		background-size: cover;
		background-position: center;
		min-height: 414px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		transition:0.3s;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom, #000, transparent);
			content: "";
			opacity: .6;
		}
		.composant-01-inner-slide{
			position: absolute;
		    left: 0;
		    top: 0;
		    width: 100%;
		    height: 100%;
		    &>.composant-01-inner-content{
		    	display: none;
		    }
		    &>.composant-01-inner-content:first-of-type{
		    	height: 100%;
		    	width: 100%;
		    	img{
		    		width: 100%;
		    		height: 100%;
		    		object-fit:cover;
		    	}
		    }
		    .slick-list{
		    	height: 100%;
		    	.slick-track{
		    		height: 100%;
		    		.slick-slide{
		    			position: relative;
		    			&:before{
		    				position: absolute;
						    top: 0;
						    left: 0;
						    width: 100%;
						    height: 100%;
						    background: linear-gradient(to bottom, #000, transparent);
						    content: "";
						    opacity: .6;
		    			}
			    		img{
			    			height: 100%;
			    			width: 100%;
			    			object-fit:cover;
			    		}
			    	}
		    	}
		    }
		}
		.composant-top-01 {
			position: relative;
		    position: fixed;
		    top: 0;
		    left: 0;
		    width: 100%;
		    max-height: 100vh;
		    overflow: auto;
		    bottom: 0;
		    pointer-events:none;
		    z-index: 999;
		    & > *{
		    	pointer-events:all;
		    }
			.composant-top-01-inner{
				position: relative;
				.container{
					display: flex;
					max-width: 1367px;
					margin: 0 auto;
					width: 90%;
					padding: 20px 15px;
					align-items: center;
					justify-content: space-between;
					transition:0.3s;
				}
				.custom-logo-link{
					img{
						max-width: 100%;
						max-height: 100%;
						width: 200px;
						height: auto;
					}
				}
				.menu-menu-container {
					flex-grow: 1;
					margin: auto;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 10px;
					ul {
						display: flex;
						max-width: 470px;
						width: 100%;
						align-items: center;
						justify-content: space-between;
						li {
							a {
								color: #fff;
								position: relative;
								text-decoration: none;
								padding: 0 2px 7px;
								display: block;
								&:hover,
								&.actif {
									font-weight: 600;
									&:before {
										width: 100%;
										height: 2px;
										position: absolute;
										bottom: 0;
										left: 0;
										content: "";
										background: #fff;
									}
								}
							}
						}
					}
					&.menu-menu-container-ascenseur{
						position: relative;
					    flex-grow: 0;
					    margin: 0 40px 0 auto;
					    height: 50px;
					    padding: 0 18px;
					    background: var(--tooltip-color);
					    border-radius: 10px;
					    cursor: pointer;
						.menu-menu-container-ascenseur-bouton{
							color: #fff;
							font-weight: 600;
							text-transform: uppercase;
						}
						.menu{
							display: none;
						    position: absolute;
						    background: transparent;
						    min-width: 160px;
						    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
						    z-index: 2;
						    top: 100%;
						    border-radius: 10px;
						    overflow: hidden;
						    padding: 10px 0;
						    &:before{
						    	position: absolute;
						    	bottom: 0;
						    	left: 0;
						    	width: 100%;
						    	height: 100%;
						    	content:"";
						    	background: var(--tooltip-color);
						    	opacity: 0.5;
						    }
						    .menu-item{
						    	display: block;
						    	a{
						    		padding: 10px 5px;
						    		color: #fff;
						    		&:before{
						    			max-width: 50px;
						    			right: 0;
						    			margin: auto;
						    			background: #fff;
						    		}
						    	}
						    }
						}

						&:hover,
						&.actif{
							.menu{
								display: block;
							}
						}
					}
				}
				.composant-top-button-01 {
					background: var(--tooltip-color);
					color: #fff;
					border: 0;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 50px;
					max-width: 202px;
					text-decoration: none;
					font-weight: 600;
					padding: 0 18px;
					img{
						margin-right: 10px;
					}
					&:hover{
						border: 2px solid #fff;
						padding: 0 16px;
					}
				}
				.composant-top-menu-01 {
					height: 50px;
					border: 2px solid #fff;
					width: 100%;
					color: #fff;
					max-width: 119px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-weight: 600;
					margin-left: 25px;
					padding: 0 15px;
					border-radius: 10px;
					cursor:pointer;
					transition:0.3s;
					&:hover{
						background-color:rgb(255 255 255 / 20%);
					}
					.composant-top-menu-01-icon-menu {
						display: block;
						width: 24px;
						margin: 10px 0;
						position: relative;
						height: 2px;
						background: #fff;
						border-radius: 10px;
						transition:0.3s;
						&:before {
							height: 2px;
							background: #fff;
							position: absolute;
							top: -10px;
							left: 0;
							width: 100%;
							border-radius: 10px;
							content: "";
							transition:0.3s;
						}
						&:after {
							height: 2px;
							background: #fff;
							position: absolute;
							bottom: -10px;
							left: 0;
							width: 100%;
							border-radius: 10px;
							content: "";
							transition:0.3s;
						}
					}
				}
				.composant-top-search-01 {
					height: 50px;
					min-height: 50px;
					width: 50px;
					min-width: 50px;
					border-radius: 100%;
					border: 2px solid #fff;
					font-size: 1.5rem;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 25px;
					cursor: pointer;
					transition:0.3s;
					&:hover{
						background-color:rgb(255 255 255 / 20%);
					}
				}
			}
			.composant-top-01-menu{
				position: absolute;
			    z-index: 1;
			    left: 0;
			    right: 0;
			    height: 0;
			    overflow: hidden;
			    transition:0.3s;
			    background-color: #fff;
				.main-menu-container{
					max-width: 1380px;
					margin:auto;
					width: 100%;
					padding: 30px 20px 0;
					.main-menu{
						padding: 27px 0;
						display: flex;
						margin: 0 -15px;
						.menu-item{
							width: 100%;
							flex-grow:1;
							padding: 0 15px;
							.nav-main-link{
								color: var(--tooltip-color);
							    font-weight: 600;
							    text-decoration: none;
							    margin-bottom: 25px;
							    display: inline-block;
							}
							.nav-drop{
								.nav-drop-item{
									color: #333333;
									line-height: 1.3;
									margin-bottom: 18px;
									a{
										color: #333333;
										text-decoration: none;
										&:hover{
											color: var(--tooltip-color);
										}
									}
								}
							}
						}
					}
					.main-menu-options{
						display: flex;
					    flex-wrap: wrap;
					    align-items: center;
					    justify-content: space-between;
					    border-bottom: 2px solid #b8b8b8;
					    padding-bottom: 22px;
					    .composant-top-button-01{
					    	display: none;
					    }
						.main-menu-options-titre{
							font-size: 1.75rem;
							text-transform: uppercase;
							font-weight: 600;
							color:var(--tooltip-color);
						}
						.main-menu-options-boutons{
							display: flex;
							a{
								min-height: 44px;
								border-color: var(--tooltip-color);
								color: var(--tooltip-color);
								border-width: 1px;
								border-style: solid;
								border-radius: 10px;
								padding: 0 24px;
								display: flex;
								justify-content:center;
								align-items:center;
								transition:0.3s;
								text-decoration: none;
								background: transparent;
								margin-left: 40px;
								text-transform: uppercase;
								&.actif{
									background: var(--tooltip-color);
									color: #fff;
									&:hover{
										background: transparent;
										color: var(--tooltip-color);
									}
								}
								&:hover{
									background: var(--tooltip-color);
									color: #fff;
								}
							}
						}
					}
					.main-menu-options-boutons-mobile{
						display: none;
					}
					.menu-menu-container-mobile{
						display: none;
					}
				}
			}
		}
		.composant-middle-01-container{
			display: flex;
			max-width: 1360px;
			padding: 0 15px;
			width: 90%;
			margin: 160px auto 0;
			justify-content: space-between;
			align-items: flex-end;
			flex-wrap:wrap;
			.composant-middle-01-arrows{
				position: relative;
				display: flex;
				margin-bottom: 28px;
				.composant-01-inner-slide-next,
				.composant-01-inner-slide-prev{
					width: 50px;
					height: 50px;
					display: flex;
					align-items:center;
					justify-content:center;
					background-color: #fff;
					color: var(--tooltip-color);
					font-size: 1.5rem;
					margin: 0 5px;
					cursor: pointer;
					transition:0.3s;
					&:hover{
						color: #fff;
						background: var(--tooltip-color);
					}
				}
			}
			.composant-middle-01-dots{
				width: 100%;
				position: relative;
				margin-bottom: 56px;
				ul{
					display: flex;
					li{
						margin-right: 10px;
						button{
							border: 1px solid #fff;
							font-size: 0;
							background: transparent;
							border-radius: 100%;
							height: 12px;
							width: 12px;
							padding: 0;
						}
						&.slick-active{
							button{
								background: #fff;
							}
						}
						&:hover{
							button{
								background: rgb(255 255 255 / 50%);
							}
						}
					}
				}
			}
			.composant-middle-01{
				color: #fff;
				position: relative;
				max-width: 480px;
				width: 100%;
				padding: 25px 40px 30px;
				background: var(--tooltip-color);
				border-radius: 15px;
				margin-bottom: 8px;
				margin-top: 70px;
				& > div{
					display: none;
				}
				& > div:first-of-type{
					display: block;
				}
				.composant-middle-01-title{
				    line-height: 1.2;
				    font-weight: 600;
				    display: flex;
				    flex-direction: column;
				    text-transform: none;
				    margin-bottom: 0;
				    span{
				    	width: 100%;
					    height: 3px;
					    border-radius: 10px;
					    background: #fff;
					    max-width: 50px;
					    margin-top: 23px;
					    margin-bottom: 16px;
				    }
				}
				.composant-middle-01-texte{
					line-height: 1.2;
				}
				.composant-middle-01-lien{
					border:1px solid #fff;
					border-radius: 10px;
					height: 46px;
					text-transform: uppercase;
				    color: #fff;
				    text-decoration: none;
				    display: inline-flex;
				    align-items: center;
				    justify-content: center;
				    padding: 0 23px;
				    margin-top: 22px;
				    transition:0.3s;
				    &:hover{
				    	background-color: #fff;
				   		color: var(--tooltip-color);
				    }
				}
			}
		}
		.composant-bottom-01 {
			min-height: 50px;
			display: flex;
			align-items: center;
			position: relative;
			margin-top: auto;
			.composant-bottom-01-overlay{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: var(--tooltip-color);
				opacity: 0.85;
			}
			.container {
				max-width: 1367px;
				padding: 0 15px;
				color: #fff;
				width: 100%;
				margin: auto;
				position: relative;
				a {
					color: #fff;
					text-decoration: none;
				}
				.breadcrumb_last {
					font-weight: 600;
				}
			}
		}
	}
	.composant-title-01 {
		position: relative;
		font-weight: 600;
		text-align: center;
		color: #101010;
		padding-top: 14px;
		margin-top: 59px;
		text-transform: none;
		span {
			position: absolute;
			top: 0;
			width: 100%;
			max-width: 50px;
			height: 3px;
			display: block;
			border-radius: 10px;
			left: 0;
			right: 0;
			margin: auto;
			background-color: var(--tooltip-color);
		}
	}
	.composant-sub-title-01 {
		text-align: center;
		font-weight: 400;
		margin-top: 15px;
	}
	&.menu-actif {
		.composant-01-inner {
			.composant-top-01{
				pointer-events:all;
				.composant-top-01-menu{
					overflow: hidden;
				}
				.composant-top-01-inner {
					background-color: #fff !important;
					.composant-top-menu-01,
					.composant-top-search-01{
						background: rgb(0 0 0 / 20%);
					}
					.composant-top-menu-01{
						.composant-top-menu-01-icon-menu{
							background: transparent;
							&:before{
								transform: rotate(45deg);
							    top: 0;
							    bottom: 0;
							}
							&:after{
								transform: rotate(-45deg);
							    top: 0;
							    bottom: 0;
							}
						}
					}
					.menu-menu-container ul li a{
						color: #101010;
						&:before{
							background: #101010;
						}
					}
				}
			}
		}
	}
}
body.home{
	.composant-01 .composant-01-inner{
		min-height: 625px;
	}
}
header{
	z-index: 9999;
    position: relative;
	&.headerFixed{
		.composant-01 {
			.composant-01-inner {
				.composant-top-01{
					opacity: 1;
					transition:0.3s;
					.composant-top-01-inner{
						background-color: rgb(135 135 135 / 50%);
						.container{
							padding: 10px;
							.custom-logo-link{
								.custom-logo{
									max-height: 50px;
    								width: auto;
								}
							}
						}
					}
				}
			}
		}
		&.headerFixedNone{
			.composant-01 {
				.composant-01-inner{
					z-index: -1;
					pointer-events:none;
					.composant-top-01{
						opacity: 0;
					}
				}
			}
		}
	}
}
.composant-02 {
	padding: 50px 0;
	.container {
		display: flex;
		align-items: center;
		> div {
			line-height: 1.3;
			p {
				margin-bottom: 1.6rem;
				text-transform: initial;
			}
			ul {
				li {
					margin-bottom: 10px;
					text-transform: initial;
					&:before{
						content: "";
					    background: var(--tooltip-color);
					    font-weight: bold;
					    display: inline-block;
					    width: 10px;
					    height: 10px;
					    border-radius: 100%;
					    margin-right: 10px;
					    margin-left: 2px;
					    min-width: 10px;
					    margin-top: 6px;
					}
				}
			}
			.composant-title-02 {
				font-weight: 600;
				line-height: 1.3;
				margin-bottom: 25px;
				color: var(--tooltip-color);
				text-transform: none;
			}
			.composant-button-02 {
				height: 46px;
				border-radius: 10px;
				border-style: solid;
				border-width: 1px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				max-width: 233px;
				width: 100%;
				padding: 10px;
				text-transform: uppercase;
				font-weight: 500;
				margin-top: 4px;
				text-align: center;
				border-color: var(--tooltip-color);
				color: var(--tooltip-color);
				transition:0.3s;
				&:hover{
					background-color: var(--tooltip-color);
					color: #fff;
				}
			}
			&.composant-02-image {
				min-height: 470px;
				width: calc(50% - 15px);
				background-size: cover;
				background-position: center;
			}
			&.composant-02-block {
				padding-right: 60px;
				width: calc(50% + 15px);
			}
		}
	}
	&.composant-02-left {
		.container {
			flex-direction: row-reverse;
			> div {
				.composant-title-02 {
					margin-bottom: 12px;
				}
				.composant-content-02 {
					p {
						margin-bottom: 1rem;
						text-transform: initial;
					}
				}
				&.composant-02-image {
					min-height: 456px;
				}
				&.composant-02-block {
					padding-left: 40px;
					margin-top: 14px;
				}
			}
		}
	}
}
.composant-03 {
	background-size: cover;
	background-position: center;
	margin-bottom: 40px;
	.composant-inner-03 {
		max-width: 1350px;
		width: 90%;
		margin: 0 auto;
		padding: 105px 15px;
		.composant-03-block {
			border-radius: 15px;
		    color: #fff;
		    max-width: 440px;
		    padding: 2rem;
		    display: flex;
		    flex-direction: column;
			.composant-title-03 {
				font-weight: 600;
				line-height: 1.3;
				margin-bottom: 19px;
				margin-top: auto;
				text-transform: initial;
			}
			.composant-content-03 {
				font-size: 1rem;
				line-height: 1.3;
				margin-bottom: 24px;
				text-transform: initial;
				font-weight: normal;
			}
			.composant-button-03 {
				width: 55px;
				height: 55px;
				border-radius: 100%;
				color: #fff;
				font-size: 1.875rem;
				display: flex;
				background: var(--tooltip-color);
				align-items: center;
				justify-content: center;
				margin-top: auto;
				text-decoration: none;
				transition:0.3s;
				> * {
					color: #fff;
				}
				&:hover{
					background-color: #fff;
					> * {
						color: var(--tooltip-color);
					}
				}
			}
		}
	}
}
.composant-03.composant-03-right {
	.composant-inner-03 {
		.composant-03-block {
			margin-left: auto;
		}
	}
}
.composant-04 {
	padding: 18px 0 90px;
	background: linear-gradient(to bottom, #fff 435px, #F9F9F9 435px);
	.composant-04-inner {
		max-width: 1390px;
		padding: 0 10px;
		width: 90%;
		margin: auto;
		.composant-04-title {
			position: relative;
			font-weight: 600;
			text-align: center;
			color: #101010;
			padding-top: 36px;
			margin-top: 30px;
			text-transform: uppercase;
			span {
				position: absolute;
				top: 0;
				width: 100%;
				max-width: 50px;
				height: 3px;
				display: block;
				border-radius: 10px;
				left: 0;
				right: 0;
				margin: auto;
				background:var(--tooltip-color);
			}
		}
		.composant-04-sub-title {
			text-align: center;
			font-weight: 400;
			margin-top: 19px;
			text-transform: none;
		}
		.composant-04-block-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			margin-top: 55px;
			.composant-04-block {
				margin: 10px 10px 30px;
				border-radius: 15px;
				width: calc(50% - 20px);
				display: flex;
				box-shadow: 0 4px 15px rgb(0 0 0 / 10%);
				overflow: hidden;
				min-height: 325px;
				background: #fff;
				.composant-04-block-left {
					width: 270px;
					min-width: 270px;
					max-width: 270px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.composant-04-block-right {
					padding: 40px 35px;
					display: flex;
					flex-direction: column;
					flex-grow:1;
					.composant-04-block-titre-content{
					    word-wrap: break-word;
					    overflow-wrap: break-word;
					    -webkit-hyphens: auto;
					    -moz-hyphens: auto;
					    hyphens: auto;
					    word-break: break-word;
					    color: var(--tooltip-color);
					}
					.composant-04-block-titre {
						font-size: 1rem;
						text-transform: uppercase;
						font-weight: normal;
						display: block;
						margin-top: 12px;
						word-wrap: break-word;
					    overflow-wrap: break-word;
					    -webkit-hyphens: auto;
					    -moz-hyphens: auto;
					    hyphens: auto;
					}
					.composant-04-block-sous-titre {
						font-weight: 600;
						display: block;
						text-transform: uppercase;
						margin-top: 7px;
						line-height: 1.3;
						word-wrap: break-word;
					    overflow-wrap: break-word;
					    -webkit-hyphens: auto;
					    -moz-hyphens: auto;
					    hyphens: auto;
					}
					.composant-04-block-description {
						margin-bottom: 18px;
						line-height: 1.3;
						font-size: 1rem;
						font-weight: normal;
						text-transform: none;
					}
					.composant-04-block-link {
						width: 100%;
						max-width: 165px;
						border-width: 1px;
						border-style: solid;
						min-height: 46px;
						display: flex;
						align-items: center;
						justify-content: center;
						text-decoration: none;
						text-transform: uppercase;
						border-radius: 10px;
						margin-top: auto;
						color: var(--tooltip-color);
						border-color:var(--tooltip-color);
						transition:0.3s;
						&:hover{
							background-color: var(--tooltip-color);
							color: #fff;
						}
					}
				}
			}
		}
	}
}
.composant-05{
	padding: 50px 20px 8px;
	max-width: 1330px;
    width: 100%;
    margin: auto;
	.composant-05-title{
		position: relative;
	    font-weight: 600;
	    text-align: center;
	    color: #101010;
	    padding-top: 36px;
	    margin-top: 10px;
	    text-transform: none;
		span{
			background: var(--tooltip-color);
			position: absolute;
		    top: 0;
		    width: 100%;
		    max-width: 50px;
		    height: 3px;
		    display: block;
		    border-radius: 10px;
		    left: 0;
		    right: 0;
		    margin: auto;
		}
	}
	.composant-05-sub-title{
	    text-align: center;
	    font-weight: 400;
	    margin-top: 19px;
	}
}
.composant-06{
	padding: 50px 0;
	.composant-06-inner{
		max-width: 1360px;
	    width: 100%;
	    margin: auto;
	    display: flex;
	    justify-content: space-around;
	    padding: 0 15px;
		.composant-06-column{
			display: flex;
		    flex-direction: column;
		    justify-content: center;
		    width: 50%;
		    flex-grow:1;
		    .composant-06-title{
		    	color: var(--tooltip-color);
				text-transform: none;
		    }
		    .composant-06-texte{
			    margin-top:12px;
			    line-height: 1.3;
			    li:before{
					content: "•";
				    color: var(--tooltip-color);
				    font-weight: bold;
				    display: inline-block;
				    width: 1em;
				}
		    }
		    .composant-06-lien{
		    	width: 100%;
			    max-width: 233px;
			    border-width: 1px;
			    border-style: solid;
			    min-height: 46px;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    text-decoration: none;
			    text-transform: uppercase;
			    border-radius: 10px;
			    border-color: var(--tooltip-color);
			    color: var(--tooltip-color);
			    margin-top: 30px;
			    margin-bottom: 14px;
			    text-align: center;
			    transition:0.3s;
			    &:hover{
			    	color: #fff;
			    	background-color: var(--tooltip-color);
			    }
		    }
		    iframe{
		    	margin:auto;
		    	max-width: 660px;
		    }
		    &.composant-06-column-texte{
		    	padding: 0 40px 0 0;
		    }
		}
	}
}
.composant-07{
	padding: 21px 0 37px;
	.composant-07-inner{
		max-width: 1330px;
		width: 90%;
		margin:auto;
		.composant-07-title{
			position: relative;
		    font-weight: 600;
		    text-align: center;
		    color: #101010;
		    padding-top: 33px;
		    margin-top: 45px;
		    text-transform: uppercase;
			span{
				position: absolute;
			    top: 0;
			    width: 100%;
			    max-width: 50px;
			    height: 3px;
			    display: block;
			    border-radius: 10px;
			    left: 0;
			    right: 0;
			    margin: auto;
			    background: var(--tooltip-color);
			}
		}
	}
	.composant-07-blocs{
		display: flex;
    	justify-content: center;
    	margin-top: 40px;
    	margin-bottom: 48px;
		.composant-07-bloc{
			text-align: center;
		    padding: 0 30px;
		    width: 25%;
			.composant-07-bloc-img-content{
				width: 120px;
			    height: 120px;
			    margin: auto;
			    border-radius: 100%;
			    display: flex;
			    align-items: center;
			    justify-content: center;
				.composant-07-bloc-img{
					max-height: 60px;
					max-width: 60px;
					width: auto;
					height: auto;
				}
			}
			.composant-07-bloc-title{
				margin-top: -24px;
				line-height: 1.3;
				margin-bottom: 14px;
				hyphens:auto;
				text-transform: none;
			}
			.composant-07-bloc-texte{
			    margin-top: 10px;
			    line-height: 1.3;
				text-transform: none;
				font-size: 1rem;
				font-weight: normal;
			}
			.composant-07-bloc-lien{
				color: var(--tooltip-color);
				border:1px solid var(--tooltip-color);
				background-color: transparent;
				border-radius: 10px;
			    text-transform: uppercase;
			    padding: 5px 24px;
			    min-height: 48px;
			    display: inline-flex;
			    align-items: center;
			    text-decoration: none;
			    margin-top: 30px;
			    transition:0.3s;
			    &:hover{
			    	background-color: var(--tooltip-color);
			    	color: #fff;
			    }
			}
		}
	}
}
.composant-08 {
	padding: 50px 0;
	.composant-08-inner {
		padding: 0 20px;
		.composant-08-title {
			position: relative;
			font-weight: 600;
			text-align: center;
			color: #101010;
			padding-top: 33px;
			margin-top: 10px;
			text-transform: uppercase;
			span {
				position: absolute;
				top: 0;
				width: 100%;
				max-width: 50px;
				height: 3px;
				display: block;
				border-radius: 10px;
				left: 0;
				right: 0;
				margin: auto;
				background:var(--tooltip-color);
			}
		}
		.composant-08-inner-tab {
			max-width: 1360px;
			margin: 65px auto 0;
			width: 100%;
			.composant-08-inner-tab-ul {
				display: flex;
				li {
					text-transform: uppercase;
					font-weight: 600;
					color: #101010;
					background: #f0f5fa;
					margin-right: 19px;
					display: flex;
					min-height: 76px;
					flex-grow: 1;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					transition: .3s;
					padding: 0 15px;
					text-align: center;
					position: relative;
					z-index: 0;
					&:last-of-type {
						margin-right: 0;
					}
					&:hover {
						background: var(--tooltip-color);
						color: #fff;
					}
					&:before{
						display: none;
					}
					span{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background:var(--tooltip-color);
						opacity: 0.2;
						z-index: -1;
					}
				}
			}
			.composant-08-inner-tab-content {
				border-width: 3px;
				border-style: solid;
				background: #fff;
				display: none;
				border-color: var(--tooltip-color);
				.composant-08-inner-tab-content-inner{
					padding: 45px 55px 15px;
				}
				.composant-08-inner-tab-content-button{
					display: none;
					text-transform: uppercase;
					font-weight: 600;
					padding: 26px 60px 26px 20px;
					border-top: 1px solid #EBEBEB;
					align-items: center;
					justify-content: space-between;
					cursor: pointer;
					svg{
						font-size: 1rem;
						transition:0.3s;
					}
				}
				p {
					margin-bottom: 1.4rem;
					line-height: 1.3em;
				}
				h3 {
					color: var(--tooltip-color);
					margin-bottom: 30px;
					text-transform: none;
				}
				strong{
					font-weight: 600;
				}
				ul{
					column-count: 2;
					max-width: 920px;
					column-gap: 75px;
					margin-bottom: 15px;
					li{
						display: flex;
						align-items:center;
						line-height: 1.3em;
						margin-bottom: 22px;
						break-inside: avoid-column;
						page-break-inside: avoid;
						-webkit-column-break-inside: avoid;
						display: inline-flex;
						&:before{
							content: "";
						    background-color: var(--tooltip-color);
						    font-weight: bold;
						    display: inline-block;
						    width: 53px;
						    height: 53px;
						    min-width:53px;
						    min-height: 53px;
						    background-image: url('../img/li-validation.png');
						    background-repeat: no-repeat;
						    background-position: center;
						    border-radius: 100%;
						    margin-right: 24px;
						}
					}
				}
				&.actif{
					.composant-08-inner-tab-content-inner{
						display: block;
					}
					.composant-08-inner-tab-content-button{
						svg{
							transform:rotate(180deg);
						}
					}
				}
			}
		}
	}
}
.tab-active {
	background: var(--tooltip-color) !important;
	color: #fff !important;
}
.composant-09{
	padding: 40px 20px;
	margin-bottom: 60px;
	.composant-09-inner{
		max-width: 1360px;
	    width: 100%;
	    margin: auto;
	    padding: 60px 60px 30px;
	    color: #fff;
	    border-radius: 15px;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
		.composant-09-title{
			font-weight: 600;
			text-transform: initial;
		}
		.composant-09-texte{
			line-height: 1.3;
			margin-top: 25px;
		}
		.composant-09-lien{
			width: 55px;
		    min-width: 55px;
		    min-height: 55px;
		    height: 55px;
		    border-radius: 100%;
		    color: #fff;
		    font-size: 1.875rem;
		    display: flex;
		    background: var(--tooltip-color);
		    align-items: center;
		    justify-content: center;
		    text-decoration: none;
		    margin: auto 0 auto 50px;
		    transform: translate(0px, -15px);
		    transition:0.3s;
		    i{
		    	color: #fff;
		    }
		    &:hover{
		    	background-color: #fff;
		    	color: var(--tooltip-color)
		    }
		}
	}
}
.composant-11 {
	max-width: 1400px;
	margin: auto;
	width: 100%;
	padding: 50px 0;
	.composant-11-inner {
		padding: 0 10px;
		.composant-11-title {
			position: relative;
			font-weight: 600;
			text-align: center;
			color: #101010;
			padding-top: 33px;
			margin-top: 59px;
			text-transform: uppercase;
			span {
				position: absolute;
				top: 0;
				width: 100%;
				max-width: 50px;
				height: 3px;
				display: block;
				border-radius: 10px;
				left: 0;
				right: 0;
				margin: auto;
				background: var(--tooltip-color);
			}
		}
		.slick-slider {
			display: flex;
			align-items: center;
			margin: 48px auto 93px auto;
		}
		.slick-arrow {
			z-index: 2;
			font-size: 1.875rem;
			cursor: pointer;
			width: 55px;
			min-width: 55px;
			min-height: 55px;
			height: 55px;
			border-radius: 100%;
		    color: #fff;
		    display: flex;
		    background: var(--tooltip-color);
		    align-items: center;
		    justify-content: center;
		    padding: 12px;
		    left: 0!important;
		    right: 0!important;
		    transition:0.3s;
		    &:hover{
		    	background: #4f4f4f;
		    }
			&.slick-prev{
				margin-right: -32px;
			}
			&.slick-next{
				margin-left: -32px;
			}

		}
		.fa-chevron-left {
			position: relative;
			z-index: 1;
		}
		.fa-chevron-right {
			position: relative;
			z-index: 1;
		}
		.composant-11-inner-content {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 5px;
			height: 150px;
			background-color: #F2F2F2;
		}
		.slick-slide {
			img {
				filter: grayscale(1);
				max-width: 90%;
				max-height: 90%;
				height: auto;
				width: auto;
			}
		}
	}
}
.composant-12{
	padding: 20px 0;
	.composant-12-inner{
		text-align: center;
		max-width: 1160px;
		padding: 0 15px;
		width: 100%;
		margin:auto;
		.composant-12-title{
			font-weight: 600;
			color: var(--tooltip-color);
		}
		.composant-12-texte-container{
			line-height: 1.3;
			margin-top: 28px;
			h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6{
				color: var(--tooltip-color);
			}
			p{
				margin-bottom: 1.6rem;
			}
			ul{
				li{
					margin-bottom: 10px;
					&:before{
						content: "";
					    background: var(--tooltip-color);
					    font-weight: bold;
					    display: inline-block;
					    width: 10px;
					    min-width: 10px;
					    height: 10px;
					    border-radius: 100%;
					    margin-right: 10px;
					    margin-left: 2px;
					    margin-top: 6px;
					}
				}
			}
		}
	}
	&.composant-12-colonnes{
		.composant-12-inner{
			max-width: 1360px;
			.composant-12-texte-container{
				text-align: left;
				column-count:2;
				column-gap:85px;
				p{
					margin-bottom: 1.2rem;
				}
			}
		}
	}
	&.composant-12-fond{
		color: #fff !important;
			.composant-12-inner{
				color: #fff !important;
			.composant-12-texte-container{
				color: #fff !important;
				h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6{
					color: #fff !important;
				}
			}
		}
	}
}
.composant-14{
	padding: 50px 0;
	background: linear-gradient(to bottom, #fff 300px, #F2F2F2 300px);
	.composant-14-inner{
		max-width: 1410px;
		padding: 0 15px;
		width: 100%;
		margin:auto;
		display: flex;
		.composant-14-bloc{
			text-align: center;
		    background: #fff;
		    border-radius: 15px 15px 8px 8px;
		    border-bottom-style: solid;
		    border-bottom-width: 10px;
		    box-shadow: 0 4px 15px rgb(0 0 0 / 10%);
		    padding: 51px 40px 45px;
		    margin:0 20px;
		    border-color: var(--tooltip-color);
			.composant-14-bloc-title{
				font-size: 1rem;
				text-transform: uppercase;
				font-weight: 300;
				color: var(--tooltip-color);
			}
			.composant-14-bloc-img-container{
				width: 155px;
			    height: 155px;
			    border-radius: 100%;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    margin: 20px auto 22px;
			    background:var(--tooltip-color);
				img{
					max-width: 85%;
					max-height: 85%;
					width: 100%;
				    height: 100%;
				    object-fit: scale-down;
				    object-position: center;
				}
			}
			.composant-14-bloc-texte{
				font-size: 1.75rem;
			    font-weight: 600;
			    line-height: 1.32;
			    color: var(--tooltip-color);
				text-transform: initial;
			}
		}
	}
}
.composant-15{
	padding: 0 0 80px;
	background: linear-gradient(to bottom, #fff 340px, #F2F2F2 340px);
	.composant-15-inner{
		max-width: 1390px;
		padding:0 15px;
		width: 100%;
		margin:auto;
		.composant-15-title{
			position: relative;
		    font-weight: 600;
		    text-align: center;
		    color: #101010;
		    padding-top: 33px;
		    margin-top: 15px;
		    text-transform: uppercase;
			span{
				position: absolute;
			    top: 0;
			    width: 100%;
			    max-width: 50px;
			    height: 3px;
			    display: block;
			    border-radius: 10px;
			    left: 0;
			    right: 0;
			    margin: auto;
			    background: var(--tooltip-color);
			}
		}
		.composant-15-sub-titre{
		    text-align: center;
		    font-weight: 400;
		    margin-top: 19px;
			text-transform: initial;
		}
		.composant-15-blocs{
			display: flex;
			margin-top: 70px;
			.composant-15-bloc{
				text-align: center;
			    border-radius: 15px 15px 8px 8px;
			    border-bottom-style: solid;
			    border-bottom-width: 10px;
			    box-shadow: 0 4px 15px rgb(0 0 0 / 10%);
			    padding: 50px 35px 34px;
			    margin: 0 10px;
			    flex-grow: 1;
			    width: 100%;
			    display: flex;
			    flex-direction: column;
			    background: #fff;
				.composant-15-bloc-title{
					font-size: 1rem;
					text-transform: uppercase;
					margin-bottom: 5px;
				}
				.composant-15-bloc-sub-title{
					font-size: 1.75rem;
					font-weight: 600;
					text-transform: none;
					line-height: 1.3;
					margin-bottom: 12px;
				}
				.composant-15-bloc-texte{
					line-height: 1.3;
					margin-bottom: 21px;
					font-size: 1rem;
					font-weight: normal;
					text-transform: none;
				}
				.composant-15-bloc-lien{
					width: 55px;
				    min-width: 55px;
				    min-height: 55px;
				    height: 55px;
				    border-radius: 100%;
				    color: #fff;
				    font-size: 1.875rem;
				    display: flex;
				    align-items: center;
				    justify-content: center;
				    text-decoration: none;
				    margin: auto auto 0;
				    border-width:1px;
				    border-style:solid;
				    transition:0.3s;
				    i{
				    	color: #fff;
				    }
				}
				&.electricite{
					border-bottom-color:#0750A4;
					.composant-15-bloc-title,
					.composant-15-bloc-sub-title{
						color: #0750A4;
					}
					.composant-15-bloc-lien{
						background: #0750A4;
						border-color: #0750A4;
						&:hover{
							background:#fff;
							color: #0750A4;
						}
					}
				}
				&.gaz{
					border-bottom-color:#6AB43C;
					.composant-15-bloc-title,
					.composant-15-bloc-sub-title{
						color: #6AB43C;
					}
					.composant-15-bloc-lien{
						background: #6AB43C;
						border-color: #6AB43C;
						&:hover{
							background:#fff;
							color: #6AB43C;
						}
					}
				}
				&.demenagement{
					border-bottom-color:#0097DB;
					.composant-15-bloc-title,
					.composant-15-bloc-sub-title{
						color: #0097DB;
					}
					.composant-15-bloc-lien{
						background: #0097DB;
						border-color: #0097DB;
						&:hover{
							background:#fff;
							color: #0097DB;
						}
					}
				}
			}
		}
	}
}
.composant-16{
	padding: 50px 0;
	.composant-16-inner{
		max-width: 1380px;
		width: 100%;
		margin: auto;
		.composant-16-title{
			position: relative;
		    font-weight: 600;
		    text-align: center;
		    color: #101010;
		    margin-top: 59px;
		    text-transform: uppercase;
		    padding: 33px 15px 0;
		    hyphens:auto;
		    word-break:break-word;
			span{
				position: absolute;
			    top: 0;
			    width: 100%;
			    max-width: 50px;
			    height: 3px;
			    display: block;
			    border-radius: 10px;
			    left: 0;
			    right: 0;
			    margin: auto;
			    background: var(--tooltip-color);
			}
		}
		.composant-16-blocs{
			display: flex;
			flex-wrap:wrap;
			.composant-16-bloc{
				text-decoration: none;
			    color: #101010;
			    width: 33.33%;
			    display: flex;
			    align-items: center;
			    padding: 0 15px;
			    margin-top: 45px;
			    margin-bottom: 15px;
				&:hover{
					text-decoration: underline;
				}
				.composant-16-bloc-image{
					width: 113px;
					height: 113px;
					min-height: 113px;
					min-width: 113px;
					border-radius: 100%;
					display: flex;
					align-items:center;
					justify-content:center;
					margin-right: 27px;
					img{
						max-width: 80%;
						max-height: 80%;
						height: auto;
						width: auto;
					}
				}
				.composant-16-bloc-infos{
					.composant-16-bloc-infos-name{
						font-size: 1.5rem;
						font-weight: 600;
						margin-bottom: 20px;
					}
					.composant-16-bloc-infos-texte{
						font-size: 1.25rem;
						font-weight: 300;
						span{
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}
.composant-17{
	background-position: center;
	background-size: cover;
	position: relative;
	margin: 0;
	.composant-17-overlay{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		opacity: 0.8;
		background: var(--tooltip-color);
	}
	.composant-17-inner{
		position: relative;
		color: #fff;
		max-width: 1390px;
		padding: 100px 15px 73px;
		width: 100%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		.composant-17-inner-column{
			flex-grow:1;
			padding: 0 15px 0 0;
			.composant-17-title{
				text-transform: uppercase;
				padding-top: 38px;
				position: relative;
				span{
					position: absolute;
				    top: 0;
				    width: 100%;
				    max-width: 50px;
				    height: 3px;
				    display: block;
				    border-radius: 10px;
				    left: 0;
				    right: 0;
				    background: #fff;
				}
			}
			.composant-17-sub-title{
				font-weight: 400;
				text-transform: none;
			}
			.composant-17-inner-column-picto-container{
				display: flex;
				justify-content: space-between;
				margin-top: 70px;
				max-width: 675px;
				.composant-17-inner-column-picto{
					padding: 0 10px;
					flex-grow:1;
					text-align: center;
					.composant-17-inner-column-picto-img{
						img{
							width: 100px;
							height: 100px;
							object-fit: scale-down;
							background: #fff;
    						border-radius: 100%;
						}
					}
					.composant-17-inner-column-picto-titre{
						text-transform: uppercase;
						font-weight: 600;
						margin-top: 15px;
					}
				}
			}
		}
		.composant-17-inner-column-contact{
			background: #fff;
			border-radius: 15px;
			max-width: 440px;
			color: var(--tooltip-color);
			padding: 40px 42px 30px;
			margin-bottom: 25px;
			.composant-17-inner-column-contact-title{
				line-height: 1.3;
				margin-bottom: 13px;
				text-transform: none;
			}
			.composant-17-inner-column-contact-lien{
				height: 48px;
			    border-radius: 10px;
			    border-style: solid;
			    border-width: 1px;
			    display: inline-flex;
			    align-items: center;
			    justify-content: center;
			    text-decoration: none;
			    max-width: 254px;
			    width: 100%;
			    padding: 10px;
			    text-transform: uppercase;
			    font-weight: 500;
			    margin-top: 4px;
			    color: var(--tooltip-color);
			    border-color:var(--tooltip-color);
			    text-align: center;
			    transition:0.3s;
			    &:hover{
			    	background: var(--tooltip-color);
			    	color: #fff;
			    }
			}
			.composant-17-inner-column-contact-bottom{
				display: flex;
    			align-items: center;
    			margin-top: 32px;
				.composant-17-inner-column-contact-bottom-picto{
					margin-right: 30px;
					width: 68px;
					height: 68px;
					border-radius: 100%;
					margin-bottom: 7px;
					img{
						width: 100%;
					    height: 100%;
					    object-fit: scale-down;
					    object-position: center;
					    background: var(--tooltip-color);
					    border-radius: 100%;
					}
				}
				.composant-17-inner-column-contact-bottom-right{
					p{
						margin-bottom: 8px;
					}
					.composant-17-inner-column-contact-normal{
					}
					.composant-17-inner-column-contact-big{
						font-size: 1.75rem;
						font-weight: 600;
					}
					.composant-17-inner-column-contact-small{
						font-size: 0.625rem;
					}
				}
			}
		}
	}
	&.center{
		.composant-17-inner{
			text-align: center;
			.composant-17-inner-column {
				.composant-17-title {
					span{
						margin: auto;
					}
				}
				.composant-17-inner-column-picto-container{
					margin-left: auto;
					margin-right:auto;
					max-width: 725px;
					.composant-17-inner-column-picto{
						width: 100%;
					}
				}
			}
		}
	}
}
.composant-18{
	padding: 50px 20px 0;
	.composant-18-inner{
		width: 100%;
		max-width: 1318px;
		margin: auto;
		.composant-18-title{
			position: relative;
		    font-weight: 600;
		    text-align: center;
		    color: #101010;
		    margin-top: 59px;
		    text-transform: uppercase;
		    padding: 33px 0px 0;
			span{
				position: absolute;
			    top: 0;
			    width: 100%;
			    max-width: 50px;
			    height: 3px;
			    display: block;
			    border-radius: 10px;
			    left: 0;
			    right: 0;
			    margin: auto;
			    background: var(--tooltip-color);
			}
		}
		.scrollbar-container{
			position: relative;
			opacity: 0;
			&:before{
				position: absolute;
				background:var(--tooltip-color);
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				opacity: 0.2;
				border-radius: 10px;
				content:"";
			}
			.scrollbar{
				height: 6px;
				background:var(--tooltip-color);
				border-radius: 10px;
				cursor: pointer;
			}
		}
		.composant-18-inner-table{
			// overflow: auto;
			overflow: hidden;
			padding-bottom: 55px;
			&::-webkit-scrollbar {
			  height: 6px;
			  max-width: 50%;
			}
			&::-webkit-scrollbar-track {
			  box-shadow: inset 0 0 5px var(--tooltip-color);
			  border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb {
			  background: var(--tooltip-color);
			  border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb:hover {
			  background: #b30000;
			}
			table{
				width: 100%;
			    margin-top: 65px;
				thead{
					background: var(--tooltip-color);
					tr{
						th{
							color: #fff;
							text-align: left;
							font-size: 1.5rem;
							font-weight: 600;
							.composant-18-th-inner{
								min-height: 100px;
							    padding: 10px 45px;
							    display: flex;
							    align-items: center;
								text-transform: none;
								font-weight: normal;
							}
						}
					}
				}
				tbody{
					font-size: 1.25rem;
					tr{
						&:nth-of-type(even){
							background: #F2F2F2;
						}
						td{
							&:first-of-type{
								font-weight: 600;
							}
							.composant-18-td-inner{
								min-height: 100px;
							    padding: 10px 45px;
							    display: flex;
							    align-items: center;
							    white-space: nowrap;
								text-transform: none;
								font-weight: normal;
							}
						}
					}
				}
			}
		}
	}
}
.composant-19{
	padding: 50px 0;
	.composant-19-inner{
		max-width: 1360px;
		padding: 0 15px;
		width: 100%;
		margin:auto;
		.row > * {
			margin-bottom: 16px;
		}
		.wpcf7-not-valid-tip{
			color: #FF0000;
		    font-weight: normal;
		    display: block;
		    margin-top: 9px	;
		}
		form{
			max-width: 548px;
			.wpcf7-radio{
				display: flex;
				margin-bottom: 12px;
				.wpcf7-list-item{
					margin-right: 40px;
					margin-left: 0;
					display: flex;
					align-items: center;
					input[type=radio]{
						width: 25px;
						height: 25px;
						margin: 0;
						margin-right: 15px;
					}
				}
				&.vertical{
					display: block;
					.wpcf7-list-item{
						margin-bottom: 20px;
					}
				}
			}
			label{
				width: 100%;
			}
			.wpcf7-form-control-wrap{
				position: relative;
			    width: 100%;
			    display: block;
			    input[type=text],input[type=email],select{
			    	width: 100%;
			    	border-radius: 10px;
			    	border: solid 1px #101010;
			    	height: 46px;
			    	margin-top: 13px;
			    	padding: 0 15px;
			    	margin-bottom: 25px;
			    	&.wpcf7-not-valid{
			    		margin-bottom: 0;
			    	}
			    }
			    select{
			    	background: url('../img/arrow-bottom-form.png');
			    	-webkit-appearance: none;
				    background-repeat: no-repeat;
				    background-position: calc(100% - 19px) center;
				    background-size: 24px;
				    color: #101010;
			    }
			    textarea{
			    	width: 100%;
			    	resize:none;
			    	height: 163px;
			    	border-radius: 10px;
			    	border: solid 1px #101010;
			    	margin-top: 13px;
			    	padding: 15px;
			    }
			}
			input[type=submit]{
		    	border: 1px solid #0D2789;
		    	background: #fff;
		    	height: 46px;
		    	border-radius: 10px !important;
		    	text-transform: uppercase;
		    	padding: 0 25px;
		    	color: #0D2789;
		    	margin-top: 18px;
		    	transition:0.3s;
		    	&:hover{
		    		background: #0D2789;
		    		color: #fff;
		    	}
		    }
		}
	}
}
.composant-20{
	padding: 50px 0;
	.composant-20-inner{
		max-width: 1360px;
		padding: 0 15px;
		width: 100%;
		margin:auto;
		display: flex;
		.composant-20-column{
			flex-grow:1;
			&.composant-20-column-text{
				padding-right: 15px;
			    width: 50%;
			    display: flex;
			    flex-direction: column;
			    justify-content: center;
			}
			&.composant-20-column-charts{
				height: auto;
				width: 50%;
				min-width: 580px;
				.composant-20-column-donnees{
					height: 100%;
				    display: flex;
				    align-items: center;
				    width: 100%;
				    padding-left: 15px;
				    padding-right: 15px;
				    padding-bottom: 25px;
				    flex-grow: 1;
				    justify-content: center;
					.piechart{
					    height: 280px;
					    min-height: 280px;
					    width: 280px;
					    min-width: 280px;
					}
					.piechart-legend{
						margin-left: 35px;
						tr{
							display: flex;
						 	align-items:center;
						 	margin-bottom: 6px;
							td{
								font-size: 1rem;
								text-transform: none;
    							font-weight: normal;
							}
						}
						span{
							height: 20px;
							width: 20px;
							border-radius: 100%;
							display: block;
							margin-right: 12px;
						}
					}
				}
			}
			.composant-20-title{
				color: var(--tooltip-color);
			}
			.composant-20-texte{
				line-height: 1.3;
				margin-top: 6px;
			}
			.composant-20-lien{
				width: 100%;
			    max-width: 213px;
			    border-width: 1px;
			    border-style: solid;
			    min-height: 46px;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    text-decoration: none;
			    text-transform: uppercase;
			    border-radius: 10px;
			    border-color: var(--tooltip-color);
			    color: var(--tooltip-color);
			    margin-top: 13px;
			    margin-bottom: 14px;
			    text-align: center;
			    transition:0.3s;
			    &:hover{
			    	background: var(--tooltip-color);
			    	color: #fff;
			    }
			}
		}
	}
}
.page-template-page-contact{
	main{
		.header-logo{
			background: #EFEFEF;
			padding: 20px;
			display: flex;
			align-items:center;
    		justify-content: center;
			img{
				max-width: 261px;
			}
		}
		.page-contact{
			display: flex;
			.page-contact-left{
				width: 58%;
				.page-contact-left-inner{
					max-width: 820px;
					padding: 20px;
					margin-left: auto;
					.page-contact-left-inner-content{
						max-width: 680px;
						width: 100%;
						.page-contact-back-link{
							color: var(--tooltip-color);
							display: inline-flex;
							align-items:center;
							text-decoration: none;
							text-transform: uppercase;
							margin-top: 27px;
							.fa-chevron-left{
								cursor: pointer;
							    width: 30px;
							    min-width: 30px;
							    min-height: 30px;
							    height: 30px;
							    border-radius: 100%;
							    color: #fff;
							    font-size: 1.875rem;
							    display: flex;
							    background: var(--tooltip-color);
							    align-items: center;
							    justify-content: center;
							    padding: 7px;
							    margin-right: 16px;
							}
						}
						.page-contact-back-breadcrumb{
							margin-top: 52px;
							margin-bottom: 17px;
							* {
								text-decoration: none;
								color: #101010;
							}
						}
						.page-contact-title{
							margin-bottom: 44px;
							line-height: 1.4;
						}
					}
				}
				form{
					br{
						display: none;
					}
					.devis__steps{
						position: relative;
					    width: 100%;
					    height: 100%;
						.devis__step{
							opacity: 0;
						    transition: 0.4s ease-in;
						    pointer-events: none;
						    top: 0;
						    width: 100%;
							&.devis__step--active {
							    transition: 0.7s ease-out;
							    transition-delay: 0.4s;
							    pointer-events: all;
							    opacity: 1;
							    padding-bottom: 80px;
							}
							&.devis__step--next{
							    transform: translateX(8px);
							    position: absolute;
							    height: 100%;
							    overflow: hidden;
							}
							&.devis__step--prev {
							    transform: translateX(-8px);
							    position: absolute;
							    height: 100%;
							    overflow: hidden;
							}
							.text-secondary{
								*{
									color: var(--tooltip-color);
									font-size: 1.5rem;
								}
								strong{
									font-weight: 600;
								}
							}
							h2{
								font-weight: 500;
							}
							.devis__demandes{
								display: flex;
							    justify-content: space-between;
							    margin: 42px -5px 0;
							    font-size:1.25rem;
							    color: var(--tooltip-color);
								*{
									color: var(--tooltip-color);
									font-weight: 600;
								}
								img{
									height: 77px;
									object-fit:scale-down;
									margin-bottom: 15px;
								}
								.devis-button-image{
									background: var(--tooltip-color);
									height: 77px;
									width: 100%;
									margin-bottom: 15px;
								}
								button{
									border: 0;
									background: #fff;
									border-radius: 15px 15px 8px 8px;
								    box-shadow: 0 4px 15px rgb(0 0 0 / 10%);
								    width: calc(100% - 20px);
								    margin: 0 10px;
								    min-height: 210px;
								    background: linear-gradient(0deg, var(--tooltip-color) 10px, transparent 10px);
								    position: relative;
								    display: flex;
								    align-items: center;
								    justify-content: center;
								    flex-direction: column;
								    padding-bottom: 10px;
								    &::after{
										display: block;
									    position: absolute;
									    top: 10px;
									    right: 10px;
									    content:"\f058";
									    background-position: center;
									    background-size: contain;
									    background-repeat: no-repeat;
									    width: 24px;
									    height: 24px;
									    opacity: 0;
									    transition: 0.2s opacity;
									    font-family: 'Font Awesome 5 Free';
									}
									&.active::after{
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
			.page-contact-right{
				width: 42%;
			    position: relative;
			    background-color: var(--tooltip-color);
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    padding: 50px;
			    min-width: 500px;
			    &.recapitulatif{
			    	align-items: flex-start;
			    	.page-contact-right-column-contact{
			    		display: none;
			    	}
			    	.page-contact-recapitulatif{
			    		display: block;
			    	}
			    }
				.page-contact-right-overlay{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-size: cover;
					opacity: 0.3;
				}
				.page-contact-right-column-contact{
					background: #fff;
					border-radius: 15px;
					max-width: 440px;
					color: var(--tooltip-color);
					padding: 40px 42px 30px;
					margin-bottom: 25px;
					position: relative;
					.page-contact-right-column-contact-title{
						line-height: 1.3;
						margin-bottom: 13px;
					}
					.page-contact-right-column-contact-lien{
						height: 48px;
					    border-radius: 10px;
					    border-style: solid;
					    border-width: 1px;
					    display: inline-flex;
					    align-items: center;
					    justify-content: center;
					    text-decoration: none;
					    max-width: 254px;
					    width: 100%;
					    padding: 10px;
					    text-transform: uppercase;
					    font-weight: 500;
					    margin-top: 4px;
					    color: var(--tooltip-color);
					    border-color:var(--tooltip-color);
					    text-align: center;
					    transition:0.3s;
					    &:hover{
					    	color: #fff;
					    	background:var(--tooltip-color);
					    }
					}
					.page-contact-right-column-contact-bottom{
						display: flex;
		    			align-items: center;
		    			margin-top: 32px;
						.page-contact-right-column-contact-bottom-picto{
							margin-right: 30px;
							width: 68px;
							height: 68px;
							border-radius: 100%;
							margin-bottom: 7px;
							img{
								width: 100%;
								height: 100%;
								object-fit:scale-down;
								object-position:center;
								background: var(--tooltip-color);
								border-radius: 100%;
							}
						}
						.page-contact-right-column-contact-bottom-right{
							p{
								margin-bottom: 8px;
							}
							.page-contact-right-column-contact-normal{
							}
							.page-contact-right-column-contact-big{
								font-size: 1.75rem;
								font-weight: 600;
							}
							.page-contact-right-column-contact-small{
								font-size: 0.625rem;
							}
						}
					}
				}
				.page-contact-recapitulatif{
					display: none;
					color: #fff;
				    position: relative;
				    width: 100%;
				    max-width: 460px;
				    padding: 50px 20px;
				    position: sticky;
    				top: 0;
				    .page-contact-recapitulatif-title{
				    	font-size: 1.75rem;
					    font-weight: 800;
					    line-height: 1.3;
					    margin-bottom: 30px;
				    }
				    .page-contact-recapitulatif-container-bloc{
				    	font-size: 1.25rem;
					    line-height: 1.4;
					    padding-bottom: 25px;
					    margin-bottom: 10px;
					    border-bottom: 1px solid #fff;
					    .page-contact-recapitulatif-container-bloc-inner{
					    	display: flex;
						    align-items: center;
						    justify-content: space-between;
					    	.page-contact-recapitulatif-container-bloc-inner-left{
					    		.page-contact-recapitulatif-container-element{
							    	display: inline-block;
							    	margin-right: 5px;
							    }
					    	}
					    	.page-contact-recapitulatif-container-bloc-inner-modify{
					    		cursor: pointer;
					    		&:hover{
					    			text-decoration: underline;
					    		}
					    	}
					    }
					    &:last-of-type{
					    	border-bottom: 0;
					    }
					    &:first-of-type{
					    	border-bottom: 1px solid #fff;
					    }
				    }
				}
			}
		}
	}
}

// _____       ___   _____   _____        _____   _____       ___   _____    _____   _   _
// |  _  \     /   | /  ___| | ____|      /  ___/ | ____|     /   | |  _  \  /  ___| | | | |
// | |_| |    / /| | | |     | |__        | |___  | |__      / /| | | |_| |  | |     | |_| |
// |  ___/   / / | | | |  _  |  __|       \___  \ |  __|    / / | | |  _  /  | |     |  _  |
// | |      / /  | | | |_| | | |___        ___| | | |___   / /  | | | | \ \  | |___  | | | |
// |_|     /_/   |_| \_____/ |_____|      /_____/ |_____| /_/   |_| |_|  \_\ \_____| |_| |_|
.search {
	.search-form--text:focus-visible {
		outline: none;
	}
	.search-hr {
		width: 50px;
		background-color: var(--tooltip-color);
		height: 2px;
		margin: 60px auto 15px auto;
	}
	.search-title {
		text-transform: initial;
		font-weight: bold;
		text-align: center;
	}
	.post {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		border-top: 1px solid #707070;
		padding: 40px 0;
	}
	.post h1 a {
		font-size: 1.25rem;
		color: #000;
		text-decoration: none;
		font-weight: bold;
	}
	.search-post-content {
		font-weight: normal;
	}
	.search-result-btn {
		text-decoration: none;
		border: 1px	solid ;
		color: var(--tooltip-color);
		padding: 12px 24px;
		border-radius: 10px;
		text-transform: uppercase;
		transition: ease-in-out .3s;
	}
	.search-result-btn:hover {
		background-color: var(--tooltip-color);
		color: #fff;
	}
	.search-form-container .search-form {
		opacity: 1;
		margin: 67px auto 60px auto;
		padding: 0 0px;
		label, .search-form--text, .search-form--espace, .search-form--submit {
			color: #000;
			border-color: #000;
			border-width: 1px;
		}
		.search-form--text::placeholder {
			font-style: italic;
			color: #000;
			opacity: 0.4;
		}
	}
	.tool-pagination {
		border-top: 1px solid #101010;
    	padding-top: 60px;
		.nav-links {
			margin: 0 auto 60px auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			a{
				background: transparent;
				transition:0.3s;
				&:hover{
					background: var(--tooltip-color);
					border-color: var(--tooltip-color);
					color: #fff;
				}
			}
		}
		.page-numbers {
			border: 1px solid #101010;
			height: 50px;
			width: 50px;
			padding: 5px 13px;
			font-size: 1.25rem;
			text-decoration: none;
			color: #101010;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin: 10px;
		}
		.page-numbers.current {
			color: var(--tooltip-color);
			border-color: var(--tooltip-color);
			font-weight: bold;
			border-width: 2px;
			&:hover{
				background: transparent;
			}
		}
	}
}
footer{
	.footer-top{
		color: #fff;
		background: var(--tooltip-color);
		text-align: center;
		padding: 61px 20px 25px;
		.footer-top-titre{
			font-weight: bold;
			line-height: 1.2;
		}
		.footer-top-blocs{
			display: flex;
		    max-width: 1060px;
		    width: 100%;
		    margin: auto;
		    justify-content: space-around;
		    margin-top: 60px;
		    flex-wrap: wrap;
			.footer-top-bloc{
				max-width: 330px;
				flex-grow:1;
				padding: 0 20px;
				margin-bottom: 40px;
				.footer-top-blocs-img{
					width: 45px;
					height: 45px;
					object-fit:scale-down;
					object-position:center;
				}
				.footer-top-blocs-titre{
					font-weight: 600;
					margin-top: 18px;
				}
				.footer-top-blocs-texte{
					font-size: 1.25rem;
				    margin-top: 33px;
				    line-height: 1.3;
				}
				.footer-top-blocs-lien{
					background-color: #fff;
				    min-height: 50px;
				    border: 0;
				    border-radius: 10px;
				    padding: 5px 30px;
				    text-decoration: none;
				    color: var(--tooltip-color);
				    display: inline-flex;
				    align-items: center;
				    text-align: center;
				    justify-content: center;
				    margin-top: 25px;
				    font-weight: 600;
				    transition:0.3s;
				    border: 2px solid #fff;
				    &:hover{
				    	background: var(--tooltip-color);
				    	color: #fff;
				    }
				}
			}
		}
	}
	.footer-bottom{
		text-align: center;
		padding: 0 20px;
		background:#F9F9F9;
		hr{
			border: 0;
			border-top: 2px solid #707070;
			max-width: 1337px;
			margin: auto;
			width: 100%;
		}
		.footer-bottom-logo-container{
			padding: 59px 0;
			.footer-bottom-logo{
				max-width: 275px;
				width: auto;
				height: auto;
			}
		}
		.footer-bottom-liens-sites{
			display: flex;
		    flex-wrap: wrap;
		    align-items: center;
		    justify-content: center;
		    margin-bottom: 11px;
		    transition:0.3s;
			a{
				text-decoration: none;
				font-size: 1.25rem;
				text-transform: uppercase;
				color: #101010;
				margin: 0 30px 30px;
				transition:0.3s;
				&:hover{
					color: var(--tooltip-color);
					font-weight: 600;
				}
			}
		}
		.footer-bottom-blocs-liens{
			max-width: 1340px;
			width: 100%;
			margin:auto;
			display: flex;
			margin-top: 40px;
			justify-content: space-between;
			flex-wrap:wrap;
			.footer-bottom-blocs-liens-solo{
			    text-align: left;
			    display: flex;
			    flex-direction: column;
			    max-width: 234px;
			    padding: 0 15px 38px;
			    .footer-bottom-blocs-liens-solo-titre{
			    	margin-bottom: 28px;
			    }
				.footer-bottom-blocs-liens-solo-titre,
				.footer-bottom-blocs-liens-solo-titre *{
					font-weight: 600;
					color: #101010;
					text-decoration: none;
				}
				.footer-bottom-blocs-liens-solo-lien{
					text-decoration: none;
					color: #101010;
					line-height: 1.2;
					margin-bottom: 21px;
				}
				a{
					&:hover{
						color: var(--tooltip-color);
					}
				}
			}
		}
	}
	.footer-mentions{
		background: #101010;
		color: #fff;
		.footer-mentions-container{
			max-width: 1340px;
		    width: 100%;
		    margin: auto;
		    display: flex;
		    flex-wrap: wrap;
		    align-items: center;
		    justify-content: space-between;
		    padding: 22px 20px;
			*{
				color: #fff;
				text-decoration: none;
			}
			a{
				&:hover{
					color: var(--tooltip-color);
				}
			}
			.footer-mentions-left{

			}
			.footer-mentions-right{
				display: flex;
				align-items: center;
				margin-top: 5px;
				img{
					max-width: 107px;
					width: auto;
					height: auto;
					margin-top: 2px;
					margin-left: 5px;
				}
			}
		}
	}
}
body{
	&.no-menu{
		.composant-01 .composant-01-inner .composant-top-01 .composant-top-01-inner .composant-top-menu-01,
		.composant-01 .composant-01-inner .composant-top-01 .composant-top-01-inner .composant-top-search-01{
			display: none;
		}
	}
}

// _____   _____   _____        ___  ___   _   _   _           ___   _   _____    _____        _____  __    __  __   _       ___       ___  ___   _   _____     _   _   _____
// |  ___| /  _  \ |  _  \      /   |/   | | | | | | |         /   | | | |  _  \  | ____|      |  _  \ \ \  / / |  \ | |     /   |     /   |/   | | | /  _  \   | | | | | ____|
// | |__   | | | | | |_| |     / /|   /| | | | | | | |        / /| | | | | |_| |  | |__        | | | |  \ \/ /  |   \| |    / /| |    / /|   /| | | | | | | |   | | | | | |__
// |  __|  | | | | |  _  /    / / |__/ | | | | | | | |       / / | | | | |  _  /  |  __|       | | | |   \  /   | |\   |   / / | |   / / |__/ | | | | | | | |   | | | | |  __|
// | |     | |_| | | | \ \   / /       | | | |_| | | |___   / /  | | | | | | \ \  | |___       | |_| |   / /    | | \  |  / /  | |  / /       | | | | | |_| |_  | |_| | | |___
// |_|     \_____/ |_|  \_\ /_/        |_| \_____/ |_____| /_/   |_| |_| |_|  \_\ |_____|      |_____/  /_/     |_|  \_| /_/   |_| /_/        |_| |_| \_______| \_____/ |_____|
//
body{
	&.page-template-page-contact{
		header{
			display: none;
		}
		footer{
			.footer-top{
				display: none;
			}
		}
		main{
			max-width: 100%;
			padding: 0;
			.gform_wrapper{
				form{
					.validation_error{
						margin-top: 30px;
					}
					.gform-step-statut{
						font-size: 1.25rem;
						font-weight: 700;
						color: var(--tooltip-color);
						text-transform: uppercase;
						.gform-step-statut-single{

						}
						.gform-step-statut-all{

						}
					}
					.gform_body{
						fieldset{
							transform:translateX(100px);
							&.actif{
								transform:translateX(0);
								display: block;
							}
							&.prev{
								transform:translateX(-100px);
							}
							&.next{
								transform:translateX(100px);
							}
							&.disabled{
								.gfieldset-legend{
									display: none;
								}
							}
							.gfieldset-legend{
								font-size: 1.75rem;
							    margin-top: 27px;
							    margin-bottom: 42px;
							}
							ul{
								&.gform_fields{
									li{
										select,
										input{
											&.medium{
												width: 100%;
											    min-height: 42px;
											    margin: 0;
											    color: #000;
											}
										}
										input[type=radio],
										input[type=checkbox]{
											position: absolute;
    										left: -9999px;
											+ label{
												position: relative;
											    padding-left: 1.95em;
											    cursor: pointer;
											    display: inline-block;
											    margin: 2px 0 0 4px;
												&:before{
													content: "";
												    position: absolute;
												    left: 0;
												    top: 0;
												    width: 1.25em;
												    height: 1.25em;
												    border: 2px solid var(--tooltip-color);
												    background: #fff;
												}
												&:after{
													content: "✔";
												    position: absolute;
												    top: 0.1em;
												    left: 0.1em;
												    font-size: 1.3em;
												    line-height: .8;
												    color: var(--tooltip-color);
												    transition: all .2s;
												    opacity: 0;
    												transform: scale(0);
												}
											}
											&:checked + label:after{
												opacity: 1;
    											transform: scale(1);
											}
										}
										select{
											height: 42px;
											background:#fff;

										}
										&.button-style{
											.ginput_container_radio{
												ul{
													&.gfield_radio{
														display: flex;
													    align-items: center;
													    justify-content: center;
														li{
														    position: relative;
														    display: flex;
														    flex-grow: 1;
														    min-height: 210px;
														    max-width: 210px;
														    pointer-events: none;
															input{
																display: none;
																&:checked + label{
																	color: #fff;
																	&:after{
																		background:var(--tooltip-color);
																	}
																	&:before{
																		color: #fff;
																	}
																}
															}
															input[value*="Oui"] + label{
																&:before{
																	content:"\f00c";
																}
															}
															input[value*="Non"] + label{
																&:before{
																	content:"\f00d";
																}
															}
															label{
																color: var(--tooltip-color);
															    font-size: 1.25rem;
															    font-weight: 600;
															    width: 100%;
															    text-align: center;
															    align-items: center;
															    flex-direction: column;
															    pointer-events: all;
															    overflow: hidden;
															    max-width: 100%;
															    height: auto;
															    display: flex;
															    justify-content: center;
															    margin: 10px;
															    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
															    border-radius: 15px 15px 8px 8px;
															    border-bottom: 10px solid var(--tooltip-color);
															    position: relative;
															    padding: 0;
																&:after{
																	position: absolute;
																	top: 0;
																	left: 0;
																	width: 100%;
																	height: 100%;
																	content:"";
																	z-index: -1;
																	opacity: 1;
   																	transform: scale(1);
																}
																&:before{
																	font-family: "Font Awesome 5 Free";
																    font-weight: 900;
																    text-align: center;
																    color: var(--tooltip-color);
																    font-size: 3rem;
																    position: relative;
																    border: 0;
																    width: auto;
																    height: auto;
																    background:transparent;
																}
															}
														}
													}
												}
											}
										}
										&.gfield_html{
											.card{
												border: 0;
												margin-bottom: 20px;
											}
										}
									}
								}
							}
						}
					}
					.gform_footer{
    					margin: 0 -10px;
						input[type=submit]{
						    text-transform: uppercase;
						    color: var(--tooltip-color);
						    border: 1px solid var(--tooltip-color);
						    padding: 12px 25px;
						    border-radius: 10px;
						    cursor: pointer;
						    transition: .3s;
						    margin: 0 10px;
						    background:transparent;
						    &:hover{
						    	background: var(--tooltip-color);
    							color: #fff;
						    }
						}
					}
					.gform_fieldset_button_group{
						display: inline-flex;
						margin:20px -10px 0;
						.custom-button{
							text-transform: uppercase;
							color: var(--tooltip-color);
							border:1px solid var(--tooltip-color);
							padding: 12px 25px;
							border-radius: 10px;
							cursor: pointer;
							transition:0.3s;
							margin:0 10px;
							&:hover{
								background:var(--tooltip-color);
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
}
.gform_wrapper.gform_wrapper  {
	.gfield_label {
		color: var(--tooltip-color);
	}
	.ginput_container select, .ginput_container input, .select2-container--default .select2-selection--single, .ginput_container textarea {
		border-radius: 10px;
		border: 2px solid var(--tooltip-color);
		font-size: 0.875rem !important;
		overflow: auto;
		height: fit-content;
		min-height: 42px;
		.select2-selection__arrow{
			height: 100%;
		}
	}
	input[type=checkbox]:focus,input[type=radio]:focus{
		box-shadow: none !important;
	}
	input[type=checkbox],input[type=radio]{
		opacity: 0;
		width: 16px;
		height: 16px;
		margin-right: 6px ;
		transition: 0.3s ease-in-out;
	}
	input[type=checkbox] + label:before{
		content:"";
		display: block;
		position: absolute;
		border: 2px solid var(--tooltip-color);
		width: 12px;
		height: 12px;
		border-radius: 2px;
		transform: translateX(-22px) translateY(4px);
	}
	input[type=radio] + label:before{
		content:"";
		display: block;
		position: absolute;
		border: 2px solid var(--tooltip-color);
		width: 12px;
		height: 12px;
		border-radius: 50%;
		transform: translateX(-22px) translateY(4px);
	}
	input[type=checkbox]:checked + label:before,input[type=radio]:checked + label:before{
		background-color: rgba(10,34,139);
	}
	input[type=file]::file-selector-button {
		font-weight: bold;
		border: 0px solid var(--tooltip-color);
		border-right: 2px solid var(--tooltip-color);
		background-color: rgba(10,34,139);
		color: white;
		line-height: 36px;
		cursor: pointer;
	}
	.ginput_container select:focus, .ginput_container input:not([type=radio]):focus, .ginput_container input:not([type=checkbox]):focus, .select2-container--default .select2-selection--single:focus {
		outline: none;
		box-shadow: 0 0 5px #7b7b7b;
	}
	.field_sublabel_below div[class*="gfield_date_"].ginput_container label {
		color: var(--tooltip-color);
		margin-top: 5px;
		width: 100%;
	}
	.gchoice_1_1_0, .gchoice_1_1_1 {
		color: var(--tooltip-color);
	}
	.gchoice_1_1_0 input:focus, .gchoice_1_1_1 input:focus {
		box-shadow: none !important;
	}
	ul.gfield_radio {
		display: flex;
		gap: 30px;
	}
	.gsection_title {
		color: var(--tooltip-color);
	}
	.gfield>p {
		color: var(--tooltip-color);
		text-align: justify;
    	line-height: 1.2;
	}
	div.ginput_container_date input {
		text-align: center;
	}
	.gfield_date_day, .gfield_date_month {
		width: 68px;
	}
	.gfield_date_year {
		width: 80px;
	}
	.select2-container {
		width: calc(50% - 8px) !important;
	}
}
.select2-dropdown {
	font-size: 1rem;
}

// _____    _____   _____   _____   _____   __   _   _____   _   _     _   _____
// |  _  \  | ____| /  ___/ |  _  \ /  _  \ |  \ | | /  ___/ | | | |   / / | ____|
// | |_| |  | |__   | |___  | |_| | | | | | |   \| | | |___  | | | |  / /  | |__
// |  _  /  |  __|  \___  \ |  ___/ | | | | | |\   | \___  \ | | | | / /   |  __|
// | | \ \  | |___   ___| | | |     | |_| | | | \  |  ___| | | | | |/ /    | |___
// |_|  \_\ |_____| /_____/ |_|     \_____/ |_|  \_| /_____/ |_| |___/     |_____|
@media screen and (max-width: 1150px) {
	.composant-01{
	    .composant-01-inner{
		    .composant-top-01{
		    	.composant-top-01-inner {
				    .composant-top-menu-01{
				    	font-size: 0;
				    	width: auto;
				    }
				    .menu-menu-container{
				    }
				    .composant-top-button-01{
				    	width: auto;
				    }
				}
			}
		}
	}
	body.no-menu .composant-01 .composant-01-inner .composant-top-01 .composant-top-01-inner .composant-top-button-01{

	}
	.composant-04{
		.composant-04-inner{
			padding: 0 20px;
			.composant-04-block-container{
				flex-direction: column;
				.composant-04-block{
					width: 100%;
                    max-width: 600px;
					margin: 20px auto;
				}
			}
		}
	}
	footer {
		.footer-bottom {
			.footer-bottom-blocs-liens{
				justify-content: space-around;
				.footer-bottom-blocs-liens-solo{
					padding: 0 10px 38px;
					flex-grow:1;
					text-align: center;
				}
			}
		}
	}
}
@media screen and (max-width: 1024px) {
	.page-template-page-contact{
		main{
			.page-contact{
				flex-direction:column;
				.page-contact-left,
				.page-contact-right{
					width: 100%;
					min-width: 100%;
				}
				.page-contact-right{
					padding: 0 20px;
					.page-contact-right-column-contact{
						margin: 95px 0;
						padding: 40px 20px;
						.page-contact-right-column-contact-bottom {
							.page-contact-right-column-contact-bottom-picto{
								margin-right: 20px;
							}
						}
					}
					.page-contact-recapitulatif .page-contact-recapitulatif-container-bloc{
						padding-bottom: 18px;
						.page-contact-recapitulatif-container-bloc-inner{
							flex-direction:column;
							align-items: flex-start;
							.page-contact-recapitulatif-container-bloc-inner-modify{
								margin-top: 14px;
								text-decoration: underline;
							}
						}
					}
				}
				.gform_wrapper form .gform_body fieldset ul.gform_fields li.button-style .ginput_container_radio ul.gfield_radio{
					flex-direction:column;
					padding: 0 20px;
					margin:0 -20px;
					li{
						max-width: 100%;
						width: 100%;
					}
				}
			}
		}
	}
	form.search-form{
		flex-wrap:wrap;
		> label{
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
		}
		> .search-form--text{
			margin: 0;
		}
	}
	.composant-01{
	    .composant-01-inner{
		    .composant-top-01{
		    	.composant-top-01-inner{
			    	.container{
			    		justify-content: space-between;
			    	}
				    .menu-menu-container{
				    	display: none;
				    }
				    .composant-top-button-01{
				    	display: none;
				    }
				    .composant-top-menu-01{
				    	margin-left: auto;
				    }
				}
			}
			.composant-middle-01-container{
				.composant-middle-01{
					margin-top: 120px;
				}
				.composant-middle-01-arrows{
					display: none;
				}
			}
		}
	}
	body.no-menu{
		.composant-01 .composant-01-inner .composant-top-01 {
			.custom-logo-link{
				margin:0 0 20px;
			}
			.composant-top-01-inner{
				.container{
					flex-direction: column;
	    			align-items: center;
				}
				.composant-top-button-01{
					display: flex;
					margin: 0 0 20px;
				}
				.menu-menu-container-ascenseur{
					display: flex;
					margin: 0 0 20px;
					.menu:before{
						opacity: 0.9;
					}
					&:hover{
						.menu{
							display: none;
						}
					}
					&.actif{
						.menu{
							display: block;
						}
					}
				}
				.composant-top-search-01{
					margin:0 0 20px;
				}
				.composant-top-menu-01{
					margin:0 0 20px;
				}
			}
		}
	}
	.composant-02{
		.container{
			flex-direction:column;
			div{
				&.composant-02-block{
					width: 100%;
					padding: 0;
					margin-top: 26px;
					.composant-content-02{
						ul{
							margin-top: 33px;
						}
					}
				}
				&.composant-02-image{
					width: 100%;
					order:-1;
					min-height: 0;
					height: 65vw;
					max-height: 500px;
				}
			}
		}
		&.composant-02-left{
			.container{
				flex-direction: column;
				>div {
					&.composant-02-block{
						padding-left: 0;
						margin-top: 26px;
					}
					&.composant-02-image{
						width: 100%;
						order:-1;
						min-height: 0;
						height: 65vw;
						max-height: 500px;
					}
				}
			}
		}
	}
	.composant-06 {
		.composant-06-inner{
			flex-direction:column-reverse;
			max-width: 700px;
			.composant-06-column{
				width: 100%;
				padding: 0;
				&.composant-06-column-texte{
					padding: 0;
					margin-top: 42px;
				}
			}
		}
	}
	.composant-07{
		.composant-07-blocs{
			flex-wrap:wrap;
			.composant-07-bloc{
				width: 50%;
				margin-bottom: 55px;
			}
		}
	}
	.composant-08{
		.composant-08-inner{
			padding: 0;
			.composant-08-inner-tab{
				border-bottom: 1px solid #ebebeb;
				.composant-08-inner-tab-ul{
					display: none;
				}
				.composant-08-inner-tab-content{
					border: 0;
					display: block !important;
					.composant-08-inner-tab-content-inner{
						padding: 20px;
						display: none;
					}
					.composant-08-inner-tab-content-button{
						display: flex;
						font-size: 1rem;
					}
				}
			}
		}
	}
	.composant-09{
		.composant-09-inner{
			padding: 34px 27px 16px;
			flex-direction: column;
			align-items: flex-start;
			.composant-09-title{
				line-height: 1.35;
			}
			.composant-09-texte{
				margin-top: 16px;
			}
			.composant-09-lien{
				margin: 15px 0 0;
			}
		}
	}
	.composant-12.composant-12-colonnes{
		.composant-12-inner{
			.composant-12-texte-container{
				column-count:1;
			}
		}
	}
	.composant-15 {
		.composant-15-inner {
			.composant-15-blocs{
				flex-direction:column;
				.composant-15-bloc{
					margin: 0 0 40px;
				}
			}
		}
	}
	.composant-16{
		.composant-16-inner{
			.composant-16-blocs{
				.composant-16-bloc{
					width: 50%;
				}
			}
		}
	}
	.composant-17 {
		.composant-17-inner{
			flex-direction: column;
			.composant-17-inner-column{
				text-align: center;
				padding: 0;
				.composant-17-title{
					span{
						right: 0;
						margin:auto;
					}
				}
				.composant-17-inner-column-picto-container{
					max-width: 100%;
					margin-bottom: 20px;
					.composant-17-inner-column-picto{
						width: 100%;
					}
				}
			}
		}
	}
	.composant-20 {
		.composant-20-inner{
			flex-direction:column;
			.composant-20-column{
				&.composant-20-column-text{
					width: 100%
				}
				&.composant-20-column-charts{
					width: 100%;
				}
			}
		}
	}
	footer {
		.footer-bottom {
			.footer-bottom-blocs-liens{
				.footer-bottom-blocs-liens-solo{
					max-width: 224px;
					width: 100%;
				}
			}
		}
		.footer-mentions {
			.footer-mentions-container {
				*{
					width: 100%;
				}
			}
		}
	}

	/*----- Menu responsive ---------*/


	body.tablet {
		.composant-01 {
			.composant-01-inner {
				.composant-top-01 {
					.composant-top-01-menu {
						.main-menu-container {
							padding: 10px 0 0;
							.main-menu-options{
								flex-direction: column;
								border: 0;
								padding-bottom: 18px;
								.main-menu-options-titre{
									margin-top: 32px;
								}
								.main-menu-options-boutons{
									display: none;
								}
								.composant-top-button-01{
									background: var(--tooltip-color);
								    color: #fff;
								    border: 0;
								    border-radius: 10px;
								    display: flex;
								    align-items: center;
								    justify-content: space-between;
								    height: 50px;
								    width: 100%;
								    max-width: 202px;
								    text-decoration: none;
								    font-weight: 600;
								    padding: 0 18px;
								}
							}
						}
						.main-menu{
							flex-direction: column;
							padding-top: 0;
							.menu-item {
								.nav-main-link{
									text-align: center;
								    min-height: 65px;
								    border-top: 1px solid #707070;
								    display: flex;
								    align-items: center;
								    justify-content: center;
								    margin: 0;
								}
								.nav-drop{
									height: 0;
									overflow: hidden;
									.nav-drop-item{
										text-align: center;
									    margin: 0;
									    color: #fff;
									    background: var(--tooltip-color);
									    border-bottom: 1px solid #fff;
									    &:last-of-type{
									    	border-bottom: 0;
									    }
									    a{
									    	min-height: 50px;
									    	display: flex;
									    	align-items: center;
									    	justify-content: center;
									    	color: #fff;
									    }
									}
								}
								&:last-of-type{
									.nav-main-link{
										border-bottom: 1px solid #707070;
									}
								}
							}
						}
						.main-menu-options-boutons-mobile{
							display: flex;
							flex-direction:column;
							align-items: center;
							margin-top: 40px;
							a{
								min-height: 44px;
							    border-color: var(--tooltip-color);
							    color: var(--tooltip-color);
							    border-width: 1px;
							    border-style: solid;
							    border-radius: 10px;
							    padding: 0 24px;
							    display: flex;
							    justify-content: center;
							    align-items: center;
							    transition: .3s;
							    text-decoration: none;
							    background: transparent;
							    text-transform: uppercase;
							    margin-bottom: 25px;
							    &:hover{
							    	background: var(--tooltip-color);
    								color: #fff;
							    }
							    &.actif{
							    	background: var(--tooltip-color);
    								color: #fff;
    								&:hover{
    									background: transparent;
    									color: var(--tooltip-color);
    								}
							    }
							}
						}
						.menu-menu-container-mobile{
							color: #101010;
							display: block;
							padding: 36px 0;
							ul{
								display: flex;
							    align-items: center;
							    flex-direction:column;
								li{
									display: flex;
									margin-bottom: 32px;
									a{
 										position: relative;
									    text-decoration: none;
									    padding: 0 0px 4px;
									    color: #101010;
									    &:hover,
									    &.actif{
									    	font-weight: 600;
									    	&:before{
										    	width: 100%;
											    height: 2px;
											    position: absolute;
											    bottom: 0;
											    left: 0;
											    content: "";
											    background: #101010;
										    }
									    }
									}
								}
							}
						}
					}
				}
			}
		}
	}


}

@media screen and (max-width: 767px) {
	header{
		&.headerFixed{
			.composant-01 {
				.composant-01-inner {
					.composant-top-01{
						.composant-top-01-inner{
							.container{
								.custom-logo-link{
									margin-bottom: 0px;
								}
								.menu-menu-container{
									&.menu-menu-container-ascenseur{
										margin-bottom: 10px;
										margin-top: 10px;
									}
								}
								.composant-top-button-01{
									margin-bottom: 10px;
								}
								.composant-top-menu-01{
									height: 40px;
    								padding: 0 7px;
								}
								.composant-top-search-01{
									height: 40px;
								    min-height: 40px;
								    min-width: 40px;
								    width: 40px;
								}
								.composant-top-button-01{
							    	height: 35px;
								}
								.menu-menu-container{
									&.menu-menu-container-ascenseur{
										height: 35px;
										.menu-menu-container-ascenseur-bouton{
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.container{
		padding: 0 20px;
	}
	form.search-form{
		flex-direction:column;
		> .search-form--text{
			width: 100%;
			margin-left: 0;
			margin-top: 10px;
		}
		> .search-form--espace,
		> #custom-dropdown-search{
			width: 100%;
			margin-left: 0;
			margin-top: 10px;
		}
		> .search-form--submit{
			width: 100%;
			margin-left: 0;
			margin-top: 10px;
		}
	}
	.composant-01{
		.composant-01-inner{
			min-height: 510px;
			.composant-top-01{
				.custom-logo-link{
					max-width: 189px;
					margin-right: 15px;
				}
			}
			.composant-middle-01-container{
				.composant-middle-01-dots{
					margin-bottom: 20px;
					ul{
						align-items: center;
    					justify-content: center;
					}
				}
				.composant-middle-01 {
					padding: 34px 27px 36px 30px;
					margin-top: 340px;
					margin-bottom: 6px;
					.composant-middle-01-title{
						line-height: 1.35;
					}
					.composant-middle-01-texte{
						line-height: 1.35;
					}
					.composant-middle-01-lien{
						margin-top: 12px;
					}
				}
			}
		}
		.composant-title-01{
			line-height: 1.2;
		}
		.composant-sub-title-01{
			line-height: 1.3;
		}
		.container{
			padding: 0 20px;
		}
	}
	body.no-menu{
		.composant-01 .composant-01-inner .composant-middle-01-container .composant-middle-01{
			margin-top: 180px;
		}
	}
	.composant-02{
		.container{
			> div{
				ul{
					li{
						margin-bottom: 15px;
					}
				}
				.composant-title-02{
					margin-bottom: 32px;
				}
				.composant-button-02{
					margin: 38px auto 0;
					display: flex;
				}
			}
		}
	}
	.composant-03 {
		margin-bottom: 20px;
		.composant-inner-03 {
			padding: 300px 20px 20px;
			.composant-03-block{
				padding: 33px 14px 37px 30px;
			}
		}
	}
	.composant-04{
		padding-top: 40px;
		background: linear-gradient(to bottom, #fff 660px, #F9F9F9 660px);
		.composant-04-inner{
			.composant-04-title{
				line-height: 1.4;
				max-width: 300px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				padding-top: 30px;
			}
			.composant-04-sub-title{
				max-width: 340px;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				line-height: 1.1;
				margin-top: 0;
			}
			.composant-04-block-container{
				margin-top: 40px;
				.composant-04-block{
					flex-direction:column;
					max-width: 400px;
					margin: 20px auto;
					.composant-04-block-left{
						width: 100%;
						max-width: 100%;
						min-width: 100%;
						height: 210px;
					}
					.composant-04-block-right{
						padding: 27px 20px;
						.composant-04-block-titre-content{
							margin-bottom: 19px;
						}
						.composant-04-block-description{
							margin-bottom: 36px;
						}
						.composant-04-block-link{
							margin-bottom: 19px;
							text-align: center;
						}
					}
				}
			}
		}
	}
	.composant-05{
		padding: 50px 25px;
		.composant-05-title{
			line-height: 1.4;
			padding-top: 30px;
			margin-top: 12px;
		}
		.composant-05-sub-title{
			line-height: 1.1;
		}
	}
	.composant-06 {
		.composant-06-inner{
			.composant-06-column{
				.composant-06-title {
	    			line-height: 1.3;
				}
				&.composant-06-column-video{
					video,iframe{
						height: 196px;
						height: 50vw;
					}
				}
				.composant-06-lien{
					margin-top: 10px;
				}
			}
		}
	}
	.composant-07{
		padding: 37px 20px 25px;
		.composant-07-inner{
			.composant-07-blocs{
				margin-top: 60px;
				.composant-07-bloc{
					width: 100%;
				}
			}
			.composant-07-title {
			    padding-top: 23px;
			    line-height: 1.4;

			}
		}
	}
	.composant-08{
		.composant-08-inner{
			.composant-08-title{
				line-height: 1.4;
				padding-top: 25px;
			}
			.composant-08-inner-tab{
				margin: 43px auto 0;
				.composant-08-inner-tab-content{
					h3{
						line-height: 1.3;
					}
					ul{
						column-count:1;
						margin-top: 40px;
						li{
							margin-bottom: 35px;
						}
					}
				}
			}
		}
	}
	.composant-09{
		.composant-09-inner{
			padding: 34px 20px 16px 30px;
			.composant-09-title{
				margin-bottom: 15px;
			}
		}
	}
	.composant-11{
		.composant-11-inner{
			.composant-11-title{
			    padding: 28px 25px 0;
			    line-height: 1.4;
			}
			.slick-slider{
				margin-top: 40px;
			}
			.composant-11-inner-content{
				height: 190px;
			}
		}
	}
	.composant-12{
		.composant-12-inner{
			padding: 0 20px;
			.composant-12-title{
				line-height: 1.4;
			}
			.composant-12-texte-container{
				margin-top: 33px;
				ul{
					margin-top: 60px;
					li{
						margin-bottom: 21px;
					}
				}
				h2,.h2{
				}
			}
		}
	}
	.composant-14{
		.composant-14-inner{
			padding: 0 20px;
			flex-direction:column;
			.composant-14-bloc{
				margin: 0 0 40px;
				padding: 51px 20px 42px;
			}
		}
	}
	.composant-15{
		background: linear-gradient(to bottom, #fff 490px, #F2F2F2 490px);
		.composant-15-inner{
			padding: 0 20px;
			.composant-15-title{
				line-height: 1.4;
				padding-top: 28px;
				margin-bottom: 8px;
				margin-top: 38px;
			}
			.composant-15-sub-titre{
				line-height: 1.35;
				margin-top: 0;
			}
			.composant-15-blocs{
				margin-top: 70px;
				.composant-15-bloc{
					padding: 45px 25px 24px;
				}
			}
		}
	}
	.composant-16 {
		.composant-16-inner {
			.composant-16-title{
				padding: 24px 15px 0;
				line-height: 1.4;
			}
			.composant-16-blocs{
				.composant-16-bloc{
					width: 100%;
					flex-direction:column;
					margin-top: 32px;
					margin-bottom: 20px;
					text-align: center;
					.composant-16-bloc-image{
						margin: 0;
					}
					.composant-16-bloc-infos{
						margin-top: 12px;
					}
				}
			}
		}
	}
	.composant-17{
		.composant-17-inner{
			padding: 100px 15px 63px;
			.composant-17-inner-column{
				.composant-17-inner-column-picto-container{
					flex-direction: column;
					margin-top: 35px;
					margin-bottom: 11px;
					.composant-17-inner-column-picto{
						margin-bottom: 14px;
					}
				}
				.composant-17-title{
					padding-top: 30px;
					line-height: 1.4;
					margin-bottom: 2px;
				}
				.composant-17-sub-title{
					line-height: 1.75;
				}
			}
			.composant-17-inner-column-contact{
				padding: 40px 20px 36px;
				margin-bottom: 60px;
				.composant-17-inner-column-contact-lien{
					margin-top: 11px;
				}
				.composant-17-inner-column-contact-bottom{
					.composant-17-inner-column-contact-bottom-picto{
						margin-right: 20px;
					}
				}
			}
		}
	}
	.composant-18{
		.composant-18-inner{
			.composant-18-title{
				padding: 25px 15px 0;
				line-height: 1.4;
			}
			.composant-18-inner-table {
				overflow: hidden;

				table{
					margin-top: 40px;
					thead{
						tr{
							th{
								.composant-18-th-inner{
									min-height: 80px;
									padding: 10px 30px;
								}
							}
						}
					}
					tbody{
						tr{
							td{
								.composant-18-td-inner{
									min-height: 80px;
									padding: 10px 30px;
								}
							}
						}
					}
				}
			}
		}
	}
	.composant-20 {
		.composant-20-inner {
			.composant-20-column {
				.composant-20-title{
					line-height: 1.3;
					margin-bottom: 11px;
				}
				&.composant-20-column-charts{
					min-width: 100%;
					margin-top: 31px;
					.composant-20-column-donnees{
						flex-direction: column;
						.piechart-legend{
							margin: 31px 0 0;
						}
					}
				}
			}
		}
	}
	footer {
		.footer-top {
			padding: 100px 20px 42px;
			.footer-top-blocs{
				margin-top: 70px;
				.footer-top-bloc {
					margin-bottom: 77px;
					.footer-top-blocs-titre{
						margin-top: 22px;
					}
				}
			}
		}
		.footer-bottom {
			.footer-bottom-liens-sites{
				flex-direction:column;
				a{
					margin: 0 0 48px;
					&:last-of-type{
						margin-bottom: 33px;
					}
				}
			}
			hr{
				border-top: 1px solid #707070;
			}
			.footer-bottom-blocs-liens{
				margin-top: 60px;
				.footer-bottom-blocs-liens-solo {
					padding-bottom: 50px;
					.footer-bottom-blocs-liens-solo-lien{
						margin-bottom: 16px;
					}
				}
			}
		}
		.footer-mentions {
			.footer-mentions-container {
				flex-direction: column;
				padding-top: 28px;
				line-height: 1.25;
				* {
				    display: flex;
				    flex-direction: column;
				    align-items: center;
				    justify-content: center;
				    text-align: center;
				    width: auto;
				    margin: auto;
				}
				.footer-mentions-left-separation{
					display: none;
				}
				.footer-mentions-left{
					margin-bottom: 30px;
				}
			}
			.footer-mentions-right{
				justify-content: center;
				flex-direction: row;
    			align-items: flex-start;
    			margin-bottom: 5px;
			}
		}
	}

}
@media screen and (max-width: 380px) {
	.composant-17{
		.composant-17-inner{
			.composant-17-inner-column-contact{
				.composant-17-inner-column-contact-bottom{
					flex-direction: column;
					text-align: center;
					.composant-17-inner-column-contact-bottom-picto{
						margin-right: 0;
					}
				}
			}
		}
	}
}
